import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class PatchComplianceService {
    constructor() {
        this.patchComplianceDataSubject = new BehaviorSubject(null);
    }
    getPdfData() {
        return this.patchComplianceDataSubject.getValue();
    }
    setPdfData(data) {
        this.patchComplianceDataSubject.next(data);
    }
}
PatchComplianceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatchComplianceService_Factory() { return new PatchComplianceService(); }, token: PatchComplianceService, providedIn: "root" });
