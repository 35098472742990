import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWTService } from './jwt.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./jwt.service";
export class RestService {
    constructor(http, jwt, generateXhrLogs) {
        this.http = http;
        this.jwt = jwt;
        this.generateXhrLogs = generateXhrLogs;
    }
    /**
     * @description Generates a console log
     * @param {string} type
     * @param {string} url
     * @param {*} [dataSent]
     * @param {*} [dataReturned]
     */
    generateLogs(type, url, dataSent, dataReturned) {
        console.log(`%c ${type.toUpperCase()} API CALL TO`, 'background-color: #333; color: #98bccd;');
        console.log(url);
        console.log(`%c DATA SENT`, 'background-color: #333; color: #fac5c5;');
        console.table(dataSent);
        console.log(`%c DATA RETURNED`, 'background-color: #333; color: #f5f5f5;');
        console.table(dataReturned);
    }
    /**
     * @description Abstracts generating an HTTP promise
     * @param {string} httpMethod
     * @param {string} url
     * @param {*} [body=null]
     * @returns {Promise<any>}
     */
    generateHttpPromise(httpMethod, url, body = null) {
        let promise = null;
        if (body) {
            promise = this.http[httpMethod](url, body, {
                headers: this.buildOptions()
            })
                .toPromise()
                .catch(err => this.handleError(err));
        }
        else {
            promise = this.http[httpMethod](url, {
                headers: this.buildOptions()
            })
                .toPromise()
                .catch(err => this.handleError(err));
        }
        if (!environment.production) {
            promise.then(res => {
                if (this.generateXhrLogs) {
                    this.generateLogs(httpMethod, url, body, res);
                }
            });
        }
        return promise;
    }
    /**
     * Abstracts HTTP POST
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    post(url, body) {
        return this.generateHttpPromise('post', url, body);
    }
    /**
     * Abstracts HTTP GET
     * @param  {string}       url
     * @return {Promise<any>}
     */
    get(url) {
        return this.generateHttpPromise('get', url);
    }
    /**
     * Abstracts HTTP GET
     * @param  {string}       url
     * @return {Promise<any>}
     */
    resolve(url) {
        return this.http.get(url, {
            headers: this.buildOptions()
        });
    }
    /**
     * Abstracts HTTP DELETE
     * @param  {string}       url
     * @return {Promise<any>}
     */
    delete(url) {
        return this.generateHttpPromise('delete', url);
    }
    /**
     * Abstracts HTTP PUT
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    put(url, body) {
        return this.generateHttpPromise('put', url, body);
    }
    /**
     * Abstracts HTTP PATCH
     * @param  {string}       url
     * @param  {any}          body
     * @return {Promise<any>}
     */
    patch(url, body) {
        return this.generateHttpPromise('patch', url, body);
    }
    /**
     * Lets use set build options with auth token header on abstracted HTTP calls
     * @return {RequestOptionsArgs}
     */
    buildOptions() {
        return new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.jwt.checkToken()
        });
    }
    /**
     * Error handling for all abstracted calls, doesn't reject a Promise
     * @param  {any}          serverError
     */
    handleError(serverError) {
        try {
            console.log('Caught try', serverError);
        }
        catch (e) {
            console.log('Caught catch', e);
        }
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.JWTService), i0.ɵɵinject("generateXhrLogs", 8)); }, token: RestService, providedIn: "root" });
