import { ErrorHandler } from '@angular/core';
import { Routes } from '@angular/router';
import { IsLoggedInAuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';
import { version } from '../../package.json';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: 'https://660a602bed53462d989341e9638ed4f6@sentry.io/1285874',
    release: 'varsity-portal-front@' + version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.25,
    enabled: environment.production ? true : false
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}
const ɵ0 = () => import("./login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ1 = () => import("./main-portal/logout/logout.module.ngfactory").then(m => m.LogoutModuleNgFactory), ɵ2 = () => import("./register-confirmation/register-confirmation.module.ngfactory").then(m => m.RegisterConfirmationModuleNgFactory), ɵ3 = () => import("./register/register.module.ngfactory").then(m => m.RegisterModuleNgFactory), ɵ4 = () => import("./reset-password-confirmation/reset-password-confirmation.module.ngfactory").then(m => m.ResetPasswordConfirmationModuleNgFactory), ɵ5 = () => import("./reset-password/reset-password.module.ngfactory").then(m => m.ResetPasswordModuleNgFactory), ɵ6 = () => import("./2fa/configure/configure-2fa.module.ngfactory").then(m => m.ConfigureTwoFactorAuthModuleNgFactory), ɵ7 = () => import("./2fa/interstitial/interstitial-2fa.module.ngfactory").then(m => m.InterstitialTwoFactorAuthModuleNgFactory), ɵ8 = () => import("./portal-invite-confirmation/portal-invite-confirmation.module.ngfactory").then(m => m.PortalInviteConfirmationModuleNgFactory), ɵ9 = () => import("./portal-access/reports/reports.module.ngfactory").then(m => m.AccessReportsModuleNgFactory), ɵ10 = () => import("./static/reports/asset-inventory/asset-inventory.module.ngfactory").then(m => m.AssetInventoryModuleNgFactory), ɵ11 = () => import("./static/reports/patch-compliance/patch-compliance.module.ngfactory").then(m => m.PatchComplianceModuleNgFactory), ɵ12 = () => import("./static/reports/antivirus/antivirus.module.ngfactory").then(m => m.AntivirusModuleNgFactory), ɵ13 = () => import("./static/forms/view-request.module.ngfactory").then(m => m.ViewRequestStaticModuleNgFactory), ɵ14 = () => import("./main-portal/main-portal.module.ngfactory").then(m => m.MainPortalModuleNgFactory);
const routes = [
    {
        path: 'Login',
        loadChildren: ɵ0
    },
    {
        path: 'Logout',
        loadChildren: ɵ1
    },
    {
        path: 'RegistrationConfirmation',
        loadChildren: ɵ2
    },
    {
        path: 'Register',
        loadChildren: ɵ3
    },
    {
        path: 'ResetPasswordConfirmation',
        loadChildren: ɵ4
    },
    {
        path: 'ResetPassword',
        loadChildren: ɵ5
    },
    {
        path: 'TwoFactorAuth/Configure',
        canActivate: [IsLoggedInAuthGuardService],
        loadChildren: ɵ6
    },
    {
        path: 'TwoFactorAuth/Interstitial',
        canActivate: [IsLoggedInAuthGuardService],
        loadChildren: ɵ7
    },
    {
        path: 'Invite/Portal/:nonce',
        loadChildren: ɵ8
    },
    {
        path: 'Access/Portal/Reports/:token',
        loadChildren: ɵ9
    },
    {
        path: 'Static/Reports/AssetInventory',
        loadChildren: ɵ10
    },
    {
        path: 'Static/Reports/PatchCompliance',
        loadChildren: ɵ11
    },
    {
        path: 'Static/Reports/Antivirus',
        loadChildren: ɵ12
    },
    {
        path: 'Static/Forms/ViewRequest',
        loadChildren: ɵ13
    },
    {
        path: '',
        loadChildren: ɵ14
    },
    { path: '**', redirectTo: '' }
];
const ɵ15 = {
    appearance: 'fill'
}, ɵ16 = {
    duration: 7500,
    horizontalPosition: 'center'
}, ɵ17 = {
    hasBackdrop: true,
    width: '350px'
};
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
