import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class AssetInventoryService {
    constructor() {
        this.pdfDataSubject = new BehaviorSubject(null);
    }
    getPdfData() {
        return this.pdfDataSubject.getValue();
    }
    setPdfData(data) {
        this.pdfDataSubject.next(data);
    }
}
AssetInventoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssetInventoryService_Factory() { return new AssetInventoryService(); }, token: AssetInventoryService, providedIn: "root" });
