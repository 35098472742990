import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class StaticFormService {
    constructor() {
        this.formDataSubject = new BehaviorSubject(null);
    }
    getPdfData() {
        return this.formDataSubject.getValue();
    }
    setPdfData(data) {
        this.formDataSubject.next(data);
    }
}
StaticFormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StaticFormService_Factory() { return new StaticFormService(); }, token: StaticFormService, providedIn: "root" });
