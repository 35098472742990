import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
export class NavigationService {
    constructor(auth) {
        this.auth = auth;
        this.defaultMenuItems = [
            {
                name: 'Dashboard',
                icon: 'i-bar-chart',
                link: '/Dashboard',
                type: 'link'
            },
            {
                name: 'Support Requests',
                icon: 'i-consulting',
                link: '/MySupportTickets',
                type: 'link'
            },
            {
                name: 'My IT Team',
                icon: 'i-conference',
                link: '/MyTeam',
                type: 'link'
            }
        ];
        this.hrManagerMenuItems = [
            {
                name: 'Employee Management',
                icon: 'i-id-2',
                type: 'link',
                link: '/Admin/EmployeeManagement'
            }
        ];
        this.adminMenuItems = [
            {
                name: 'Admin',
                icon: 'i-gear',
                type: 'dropdown',
                children: [
                    {
                        name: 'Invoices',
                        icon: 'i-receipt-4',
                        type: 'link',
                        link: '/Admin/Invoices'
                    },
                    {
                        name: 'Reports',
                        icon: 'i-files',
                        type: 'dropdown',
                        children: [
                            {
                                name: 'Service Tickets',
                                type: 'link',
                                link: '/Admin/TicketReport'
                            },
                            {
                                name: 'Management Reports',
                                type: 'link',
                                link: '/Admin/ManagementReport'
                            },
                            {
                                name: 'Patch Compliance',
                                type: 'link',
                                link: '/Admin/PatchComplianceReport',
                                beta: true
                            },
                            {
                                name: 'Antivirus',
                                type: 'link',
                                link: '/Admin/AntivirusReport',
                                beta: true
                            }
                        ]
                    },
                    {
                        name: 'Asset Inventory',
                        icon: 'i-computer-2',
                        type: 'link',
                        link: '/Admin/AssetInventory',
                        beta: true
                    },
                    {
                        name: 'Employee Management',
                        icon: 'i-id-2',
                        type: 'link',
                        link: '/Admin/EmployeeManagement'
                    },
                    {
                        name: 'Workflows',
                        icon: 'i-check',
                        type: 'link',
                        link: '/Admin/Workflows/Policies'
                    },
                    {
                        name: 'Company Settings',
                        icon: 'i-gears',
                        type: 'link',
                        link: '/Admin/Settings'
                    }
                ]
            }
        ];
        this.staffMenuItems = [
            {
                name: 'Varsity Staff Panel',
                icon: 'i-administrator',
                link: '/StaffPanel',
                type: 'link'
            }
        ];
        this.sidebarState = {
            sidenavOpen: true,
            childnavOpen: false
        };
        this.menuItems = new BehaviorSubject(this.defaultMenuItems);
        this.routerOptions = {
            scrollToTop: false
        };
        this.auth.securityLevel.subscribe(securityLevel => {
            switch (securityLevel) {
                case 1:
                case 2:
                case 3:
                    this.buildUserMenu();
                    break;
                case 4:
                case 5:
                    this.buildHrManagerMenu();
                    break;
                case 6:
                    this.buildAdminMenu();
                    break;
            }
        });
        this.menuItems$ = this.menuItems.asObservable();
    }
    buildUserMenu() {
        this.menuItems.next([...this.defaultMenuItems]);
        this.checkStaffPanelAccess();
    }
    buildHrManagerMenu() {
        this.menuItems.next([...this.defaultMenuItems, ...this.hrManagerMenuItems]);
        this.checkStaffPanelAccess();
    }
    buildAdminMenu() {
        this.menuItems.next([...this.defaultMenuItems, ...this.adminMenuItems]);
        this.checkStaffPanelAccess();
    }
    checkStaffPanelAccess() {
        if (this.auth.getUser().staffFlag) {
            const menuItems = this.menuItems.getValue();
            this.menuItems.next([...menuItems, ...this.staffMenuItems]);
        }
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.ɵɵinject(i1.AuthService)); }, token: NavigationService, providedIn: "root" });
